import React, { useState } from 'react';
import { NextButton } from '../next-button';
import { Button, PasswordInput } from 'ui';
import { LegalEntitySimpleMenu } from '../common';

export const AddCircleForm = ({ onNext, onCancel, isNextDisabled }) => {
  const [legalEntityId, setLegalEntityId] = useState<string>();
  const [apiKey, setApiKey] = useState<string>('');

  return (
    <>
      <div>
        <div>
          <div className='font-medium mb-1'>Legal entities</div>
          <LegalEntitySimpleMenu value={legalEntityId} onChange={(option) => setLegalEntityId(option.value)} />
        </div>
        <div>
          <div className='font-semibold text-xl mt-4 mb-5'>Credentials</div>
          <div>
            <div className='font-medium'>API Key</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='API Key' value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
      <div className='mt-12 gap-x-3 flex items-center'>
        <Button label='Cancel' onClick={onCancel} className='rounded-full px-2' emphasis='medium' />
        <NextButton
          onClick={() => onNext({ legalEntityId, apiKey, apiSecret: 'WE_SHOULD_BE_ABLE_TO_MAKE_IT_WITHOUT_THIS' })}
          disabled={isNextDisabled}
        />
      </div>
    </>
  );
};
