import React from 'react';
import { Wallet } from 'services/http/response.types';
import { Button } from 'ui';
import { useDeleteSource } from '../../../hooks/http';
import { useSession } from '../../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { useInvalidateQuery } from '../../../hooks';

interface AddedWalletCardProps {
  wallet: Wallet;
}

export const AddedWalletCard = ({ wallet }: AddedWalletCardProps) => {
  const { organizationId } = useSession();
  const { mutateAsync: deleteSource, isLoading: isDeletingWallet } = useDeleteSource();

  const { invalidateWallets } = useInvalidateQuery();

  const onDelete = async (walletId: string) => {
    try {
      await deleteSource({ organizationId, walletId });
      toast.success('Wallet deleted successfully');
      invalidateWallets();
    } catch (error) {
      console.log('EditWalletForm error on delete: ', error);
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex items-start w-full p-6 border rounded-lg'>
      <div className='flex-grow'>
        <div className='font-semibold mb-1'>{wallet.name}</div>
        <div className='w-full wrap leading-[18px] truncate max-w-[250px]'>{wallet.address}</div>
      </div>
      <div className='flex items-center gap-x-2'>
        <Button
          emphasis='medium'
          status='danger'
          label='Remove'
          onClick={() => onDelete(wallet._id)}
          isLoading={isDeletingWallet}
          disabled={isDeletingWallet}
        />
      </div>
    </div>
  );
};
