import Image from 'next/image';
import React from 'react';
import { useLogout, useSession } from '../../../hooks';
import { Button, classNames } from 'ui';

export const OnboardingNav = ({ className = '' }) => {
  const { userId } = useSession();
  const logout = useLogout();
  return (
    <div className={classNames('w-full flex justify-between items-center py-3 px-6 font-semibold', className)}>
      <div className='flex items-center gap-3'>
        <Image width={40} height={40} alt='' src={'/svg/logo.svg'} />
        <div className='text-xl'>Entendre</div>
      </div>
      {!!userId?.length && (
        <Button data-cy='onboardingWorkspace_logoutButton' onClick={logout} emphasis='medium' label='Logout' />
      )}
    </div>
  );
};

export default OnboardingNav;
