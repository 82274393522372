import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, InputLabel } from 'ui';

interface ImageUploadInputProps {
  heading: string;
  description?: string;
  tooltip?: string;
  onSaveClick?: (file: any) => void;
  disabled?: boolean;
  isSaving?: boolean;
  showSaveButton?: boolean;
  onFileChange?: (file: any) => void;
  defaultIcon?: ReactNode;
}

const ImageUploadInput = ({
  heading,
  description,
  tooltip,
  onSaveClick,
  onFileChange,
  disabled,
  isSaving,
  showSaveButton,
  defaultIcon,
}: ImageUploadInputProps) => {
  const [file, setFile] = useState(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const onChooseImageClick = () => {
    hiddenFileInput.current?.click();
  };

  const onChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
  };

  const onSave = () => {
    if (onSaveClick) onSaveClick(file);
  };

  useEffect(() => {
    if (onFileChange) onFileChange(file);
  }, [file]);

  return (
    <div className='grid grid-cols-1 gap-4'>
      <div className='flex gap-x-6'>
        <div
          className='w-[72px] h-[72px] rounded-full bg-gray-200 overflow-clip cursor-pointer shrink-0'
          onClick={onChooseImageClick}
        >
          {!file && !!defaultIcon && (
            <span className='w-full h-full flex items-center justify-center'>{defaultIcon}</span>
          )}
          {file && <img className='w-full h-full object-cover' alt='Image' src={URL.createObjectURL(file)} />}
        </div>
        <div>
          <InputLabel heading={heading} tooltip={tooltip} />
          <p className='text-gray-500'>{description}</p>
          <span className='flex items-center gap-x-2'>
            <Button
              label={file ? 'Change image' : 'Choose image'}
              emphasis='medium'
              onClick={onChooseImageClick}
              disabled={disabled}
              className='mt-4 px-2'
            />
            {file && (
              <Button
                label={'Remove'}
                emphasis='medium'
                onClick={() => {
                  setFile(null);
                  if (hiddenFileInput?.current) {
                    hiddenFileInput.current.value = '';
                  }
                }}
                disabled={disabled}
                className='mt-4 px-2'
              />
            )}
            {file && showSaveButton && (
              <Button label='Save' emphasis='medium' onClick={onSave} isLoading={isSaving} disabled={disabled} />
            )}
          </span>
          <input
            type='file'
            ref={hiddenFileInput}
            onChange={onChange}
            style={{ display: 'none' }}
            accept='image/png, image/jpeg'
          />
        </div>
      </div>
    </div>
  );
};

export default ImageUploadInput;
