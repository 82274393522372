import React from 'react';
import { useAllWallets } from '../../../hooks/http/useAllWallets';
import { AddedWalletCard } from './AddedWalletCard';

export const AddedWalletsList = () => {
  const { wallets } = useAllWallets();
  return (
    <div className='max-w-[442px] w-full mb-10 mx-auto'>
      <div className='mb-4 text-2xl font-semibold'>Added wallets {wallets && <>({wallets.length})</>}</div>
      {wallets && (
        <div className='grid grid-cols gap-4' data-cy='onboardingWallets_addedList'>
          {wallets.map((wallet, i) => (
            <AddedWalletCard wallet={wallet} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};
