import React, { useState } from 'react';
import { Button, PasswordInput } from 'ui';
import { NextButton } from '../next-button';
import { LegalEntitySimpleMenu } from '../common';

export const AddCoinbaseForm = ({ onNext, isNextDisabled, onCancel }) => {
  const [legalEntityId, setLegalEntityId] = useState<string>();
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setPrivateKey] = useState<string>('');
  const [apiPassphrase, setPassphrase] = useState<string>('');

  return (
    <>
      <div>
        <div>
          <div className='font-medium mb-1'>Legal entities</div>
          <LegalEntitySimpleMenu value={legalEntityId} onChange={(option) => setLegalEntityId(option.value)} />
        </div>
        <div>
          <div className='font-semibold text-xl mt-4 mb-5'>Credentials</div>
          <div>
            <div className='font-medium'>Access Key</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='API Key' value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            </div>
          </div>
          <div className='mt-4'>
            <div className='font-medium'>Signing key</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='Private Key' value={apiSecret} onChange={(e) => setPrivateKey(e.target.value)} />
            </div>
          </div>
          <div className='mt-4'>
            <div className='font-medium'>Passphrase</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='Passphrase' value={apiPassphrase} onChange={(e) => setPassphrase(e.target.value)} />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-12 gap-x-3 flex items-center'>
        <Button label='Cancel' onClick={onCancel} className='rounded-full px-2' emphasis='medium' />
        <NextButton
          onClick={async () => {
            // console.log('onNext', { legalEntityId, apiKey, apiSecret, apiPassphrase });
            await onNext({ legalEntityId, apiKey, apiSecret, apiPassphrase });
          }}
          disabled={isNextDisabled}
        />
      </div>
    </>
  );
};
