import React from 'react';
import AddedEntityCard from './AddedEntityCard';
import { useAllLegalEntities } from '../../../hooks/http';

const AddedEntitiesList = () => {
  const { legalEntities } = useAllLegalEntities();
  return (
    <div className='max-w-[442px] w-full mb-10 mx-auto'>
      <div className='mb-4 text-2xl font-semibold'>Added entities ({legalEntities.length})</div>
      {legalEntities && (
        <div className='grid grid-cols gap-4' data-cy='onboardingLegalEntities_addedList'>
          {legalEntities.map((entity, i) => (
            <AddedEntityCard entity={entity} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AddedEntitiesList;
