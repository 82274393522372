import React, { useEffect, useState } from 'react';
import {
  useAllLedgerAccounts,
  useInvalidateQuery,
  useLinkedLegderAccounts,
  useUpdateLedgerAccount,
} from '../../../hooks';
import { LEDGER_ACCOUNT_TYPE, LedgerAccount } from 'services/http/response.types';
import { Button, Checkbox, InputLabel, InputWithExtras, SimpleMenu } from 'ui';
import { deriveError, getDisplayedLedgerAccounts } from '../../templates/utils';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-hot-toast';

interface FormState {
  ledgerAccountType: LEDGER_ACCOUNT_TYPE;
  ledgerAccountName: string;
  ledgerAccountSequence: string;
  parentLedgerAccountId?: string;
  isClearingAccount: boolean;
}

const defaultFormState: FormState = {
  ledgerAccountName: '',
  ledgerAccountSequence: '',
  isClearingAccount: false,
  ledgerAccountType: LEDGER_ACCOUNT_TYPE['INCOME'],
};

const LedgerAccountRow = ({ ledgerAccount, parent }: { ledgerAccount: LedgerAccount; parent?: LedgerAccount }) => {
  const [isBeingUpdated, setIsBeingUpdated] = useState(false);
  const { ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useAllLedgerAccounts();
  const [parentsWithSameType, setParentsWithSameType] = useState<LedgerAccount[]>([]);
  const [formState, setFormState] = useState<FormState>(defaultFormState);

  const { mutateAsync: patchLedgerAccount, isLoading: isPatchingLedgerAccount } = useUpdateLedgerAccount();

  useEffect(() => {
    if (ledgerAccounts && formState.ledgerAccountType)
      setParentsWithSameType(
        ledgerAccounts.filter((account) => account.ledgerAccountType === formState.ledgerAccountType),
      );
  }, [ledgerAccounts, formState]);
  const { invalidateLedgerAccounts } = useInvalidateQuery();
  useEffect(() => {
    setFormState({
      ledgerAccountName: ledgerAccount.ledgerAccountName,
      ledgerAccountSequence: `${ledgerAccount.ledgerAccountSequence}`,
      parentLedgerAccountId: parent ? parent._id : undefined,
      ledgerAccountType: ledgerAccount.ledgerAccountType,
      isClearingAccount: !!ledgerAccount.isClearingAccount,
    });
  }, [ledgerAccount, parent]);
  const onCancel = () => setIsBeingUpdated(false);
  const onSave = async () => {
    setIsBeingUpdated(false);
    let toastId: any;
    try {
      toastId = toast.loading('Updating ledger account');
      // console.log({ formState });
      await patchLedgerAccount({
        ledgerAccountId: ledgerAccount._id,
        ledgerAccount: {
          ...formState,
          ledgerAccountSequence: +formState.ledgerAccountSequence,
        },
      });
      toast.success('Ledger account details updated', { id: toastId });
      await invalidateLedgerAccounts();
    } catch (error) {
      console.log(error);
      toast.error(deriveError(error), { id: toastId });
    }
  };

  if (isBeingUpdated)
    return (
      <div className='p-4 rounded-lg'>
        <div className='mb-4 text-lg font-semibold'>Updating Account details</div>
        <div className='max-w-[442px]'>
          <div className='mb-2'>
            <InputLabel heading='Number' />
            <InputWithExtras
              value={formState.ledgerAccountSequence}
              onChange={(e) => setFormState((prev) => ({ ...prev, ledgerAccountSequence: e.target.value }))}
            />
          </div>
          <div className='mb-2'>
            <InputLabel heading='Account name' />
            <InputWithExtras
              value={formState.ledgerAccountName}
              onChange={(e) => setFormState((prev) => ({ ...prev, ledgerAccountName: e.target.value }))}
            />
          </div>
          <div className='mb-2'>
            <InputLabel heading='Type' />
            <SimpleMenu
              options={Object.values(LEDGER_ACCOUNT_TYPE).map((type) => ({ label: type, value: type as string }))}
              selectedValue={formState.ledgerAccountType as string | undefined}
              onChange={(option) => setFormState((prev) => ({ ...prev, ledgerAccountType: option?.value }))}
            />
          </div>
          <div className='mb-2'>
            <InputLabel heading='Parent' />
            <SimpleMenu
              options={getDisplayedLedgerAccounts(
                parentsWithSameType.filter((parent) => parent._id !== ledgerAccount._id),
              )}
              selectedValue={formState.parentLedgerAccountId as string | undefined}
              onChange={(option) => setFormState((prev) => ({ ...prev, parentLedgerAccountId: option?.value }))}
              isLoading={isLoadingLedgerAccounts}
            />
          </div>
          <div className='mb-2'>
            <Checkbox
              label='Clearing account'
              onChange={(isChecked) => setFormState((prev) => ({ ...prev, isClearingAccount: isChecked }))}
              isSelected={formState.isClearingAccount}
            />
          </div>
        </div>

        <div className='flex flex-row-reverse gap-x-2 '>
          <Button variant='xs' onClick={onSave} label={'Save changes'} disabled={isPatchingLedgerAccount} />
          <Button
            variant='xs'
            onClick={onCancel}
            label={'Cancel'}
            emphasis='medium'
            disabled={isPatchingLedgerAccount}
          />
        </div>
      </div>
    );
  return (
    <div className='grid grid-cols-5 p-2 items-start'>
      <div>{ledgerAccount.ledgerAccountSequence}</div>
      <div>{ledgerAccount.ledgerAccountName}</div>
      <div>
        {ledgerAccount.ledgerAccountType} {ledgerAccount.isClearingAccount && `(clearing)`}
      </div>
      <div>{parent ? `${parent.ledgerAccountSequence}: ${parent.ledgerAccountName}` : 'No parent account'}</div>
      <div className='text-right'>
        <Button
          onClick={() => setIsBeingUpdated(true)}
          disabled={isPatchingLedgerAccount}
          trailingIcon={<MdEdit className='text-zinc-500' />}
          emphasis='low'
          variant='xs'
        />
      </div>
    </div>
  );
};

const AddedLedgerAccountsList = () => {
  const { ledgerAccounts } = useLinkedLegderAccounts();

  return (
    <div className='max-w-[700px] w-full mb-10 mx-auto'>
      <div className='mb-4 text-2xl font-semibold'>
        Added ledger accounts {ledgerAccounts && <>({ledgerAccounts.length})</>}
      </div>

      {ledgerAccounts && (
        <div className='grid grid-cols-1 gap-4 border rounded-lg bg-[#F8F8F8]'>
          <div className='grid grid-cols-5 p-2 font-semibold text-zinc-500'>
            <div>Number</div>
            <div>Account name</div>
            <div>Type</div>
            <div>Parent account</div>
          </div>
          <div className='bg-white divide-y rounded-b-lg' data-cy='onboardingLedgerAccounts_addedList'>
            {ledgerAccounts.map((account) => (
              <LedgerAccountRow
                key={account._id}
                ledgerAccount={account}
                parent={ledgerAccounts.find((a) => a._id === account.parentLedgerAccountId)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddedLedgerAccountsList;
