import React, { ReactNode } from 'react';
import { Button, ButtonProps } from 'ui';
import OnboardingNav from './OnboardingNav';
interface OnboardingLayoutProps {
  children: ReactNode;
  bottomBarActions?: ButtonProps[];
  showCurrentOrg?: boolean;
}

export const BasicLayout = ({ children, bottomBarActions }: OnboardingLayoutProps) => {
  return (
    <div className='w-full h-screen flex flex-col divide-y overflow-hidden'>
      <OnboardingNav />
      <div className='flex-grow overflow-auto'>{children}</div>
      {bottomBarActions && (
        <div className='py-3 px-6 w-full flex flex-row-reverse item-center gap-x-2'>
          {bottomBarActions.map((action, i) => (
            <Button {...action} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};
