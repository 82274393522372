import React, { useState } from 'react';
import { Button, InputLabel, PasswordInput } from 'ui';
import { NextButton } from '../next-button';
import { LegalEntitySimpleMenu } from '../common';

export const AddKrakenForm = ({ onNext, isNextDisabled, onCancel }) => {
  const [legalEntityId, setLegalEntityId] = useState<string>();
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');

  return (
    <>
      <div>
        <div>
          <InputLabel heading='Legal entity' />
          <LegalEntitySimpleMenu value={legalEntityId} onChange={(option) => setLegalEntityId(option.value)} />
        </div>
        <div>
          <div className='font-semibold text-xl mt-4 mb-5'>Credentials</div>
          <div>
            <div className='font-medium'>API Key</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='API Key' value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
            </div>
          </div>
          <div className='mt-4'>
            <div className='font-medium'>Private key</div>
            <div className='font-medium mt-1'>
              <PasswordInput name='Private key' value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-12 gap-x-3 flex items-center'>
        <Button label='Cancel' onClick={onCancel} className='rounded-full px-2' emphasis='medium' />
        <NextButton
          onClick={() =>
            onNext({
              legalEntityId,
              apiKey,
              apiSecret,
            })
          }
          disabled={isNextDisabled}
        />
      </div>
    </>
  );
};
