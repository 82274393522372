import { ReactNode } from 'react';

interface OnboardingFormContainerProps {
  children: ReactNode;
  heading?: string;
  description?: string;
}

export const OnboardingFormContainer = ({ children, heading, description }: OnboardingFormContainerProps) => {
  return (
    <div className='flex items-center justify-center'>
      <div className='max-w-[442px] w-full py-20'>
        <div className='mb-16'>
          <div className='mb-3 text-2xl font-semibold'>{heading}</div>
          <div>{description}</div>
        </div>
        <div className='grid grid-cols-1 gap-5'>{children}</div>
      </div>
    </div>
  );
};
