import React from 'react';
import { LegalEntity } from 'services/http/response.types';
import { Button } from 'ui';
import { useDeleteLegalEntity } from '../../../hooks/http';
import { navigate } from '../../utils';
import { useSession } from '../../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { useInvalidateQuery } from '../../../hooks';

interface AddedEntityCardProps {
  entity: LegalEntity;
}

const AddedEntityCard = ({ entity }: AddedEntityCardProps) => {
  const { organizationId } = useSession();
  const { mutateAsync: deleteLegalEntity, isLoading: isDeletingLegalEntity } = useDeleteLegalEntity();

  const { invalidateLegalEntities } = useInvalidateQuery();

  const onDelete = async (legalEntityId: string) => {
    try {
      await deleteLegalEntity({ organizationId, legalEntityId });
      toast.success('Legal entity deleted successfully');
      invalidateLegalEntities();
    } catch (error) {
      console.log('EditLegalEntityForm error on delete: ', error);
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex items-start w-full p-6 border rounded-lg'>
      <div className='flex-grow'>
        <div className='font-semibold mb-1'>{entity.entityName}</div>
        <div className='w-full wrap leading-[18px]'>{entity.addressString}</div>
      </div>
      <div className='flex items-center gap-x-2'>
        <Button
          emphasis='medium'
          status='danger'
          label='Remove'
          onClick={() => onDelete(entity._id)}
          isLoading={isDeletingLegalEntity}
          disabled={isDeletingLegalEntity}
        />
        <Button
          emphasis='medium'
          label='Edit'
          onClick={() => navigate(`/onboarding/entities/${entity._id}/edit`)}
          disabled={isDeletingLegalEntity}
        />
      </div>
    </div>
  );
};

export default AddedEntityCard;
